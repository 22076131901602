import { Controller } from "@hotwired/stimulus";

/*
 * @action show Open the modal
 * @action hide Close the modal
 * @action hideWithEscape Press Escape to close the modal
 * @action showAnother Open a modal outside the current controller
 * @param show [String] The id attribute of another modal controller
 * @target modal [Element] Bootstrap 4 modal
 * @target backdrop [Element] Bootstrap 4 modal backdrop
 * @event modal:show Subscribe to this event to show a modal from another controller
 * @event modal:hide Subscribe to this event to hide a modal from another controller
 */
export default class extends Controller {
  static targets = ["modal", "backdrop"];

  /*
   * Abrir otro modal, enviando el ID a toda la ventana.
   */
  showAnother(event = undefined) {
    event?.preventDefault();

    if (!event.params.show) {
      console.error("data-modal-show-param attribute missing", event.target);
      return;
    }

    if (!event.target.id) {
      console.error("id attribute missing, focus won't be re-assigned when modal is closed", event.target);
    }

    window.dispatchEvent(new CustomEvent("modal:show", { detail: { id: event.params.show, previousFocus: event.target.id } }));
  }

  /*
   * Podemos enviar la orden de apertura como un click o como un
   * CustomEvent incluyendo el id del modal como detail.
   *
   * El elemento clicleable puede tener un valor que se refiera a otro
   * modal también.
   */
  show(event = undefined) {
    event?.preventDefault();
    const modalId = event?.detail?.id;

    if (modalId && this.element.id !== modalId) return;

    this.modalTarget.style.display = "block";
    this.backdropTarget.style.display = "block";
    this.modalTarget.setAttribute("role", "dialog");
    this.modalTarget.setAttribute("aria-modal", true);
    this.modalTarget.removeAttribute("aria-hidden");

    window.document.body.classList.add("modal-open");

    if (event?.detail?.previousFocus) {
      this.previousFocus = window.document.getElementById(event.detail.previousFocus);
    } else {
      this.previousFocus = event?.target;
    }

    setTimeout(() => {
      this.modalTarget.classList.add("show");
      this.backdropTarget.classList.add("show");

      this.modalTarget.focus();
    }, 1);
  }

  hideWithEscape(event) {
    if (event?.key !== "Escape") return;

    this.hide();
  }

  hide(event = undefined) {
    event?.preventDefault();
    const modalId = event?.detail?.id;

    if (modalId && this.element.id !== modalId) return;

    this.backdropTarget.classList.remove("show");
    this.modalTarget.classList.remove("show");

    this.modalTarget.setAttribute("aria-hidden", true);
    this.modalTarget.removeAttribute("role");
    this.modalTarget.removeAttribute("aria-modal");

    this.previousFocus?.focus();

    this.modalTarget.querySelectorAll("video,audio").forEach(x => x.pause());

    setTimeout(() => {
      this.modalTarget.style.display = "";
      this.backdropTarget.style.display = "";
    }, 500);

    window.document.body.classList.remove("modal-open");
  }
}
