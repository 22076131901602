import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab", "value"];
  static classes = ["hide", "show"];

  valueTargetConnected(valueTarget) {
    this.toggle();
  }

  toggle(event = undefined) {
    const value = this.valueTarget.value?.trim();

    for (const tab of this.tabTargets) {
      if (value === tab.id) {
        tab.classList.remove("d-none", ...this.hideClasses);

        setTimeout(() => tab.classList.add(...this.showClasses), 1);
      } else {
        tab.classList.add("d-none", ...this.hideClasses);
        setTimeout(() => tab.classList.remove(...this.showClasses), 1);
      }
    }
  }
}
